import ouiIcons from 'oui-icons';
import React from 'react';

import { createInstance } from '@optimizely/optimizely-sdk';
import config from 'atomic-config';
import { Navigation } from 'optimizely-oui';

import IconSprite from 'react_components/icon_sprite';
import getReactWrapperForVueComponent from 'react_components/vue_wrapper';
import { RegionComponent } from 'react_components/region';

import flux from 'core/flux';
import CoreUIActionTypes from 'core/ui/action_types';

import DialogManager from 'core/ui/components/dialog_manager';
import NotifcationManager from 'core/ui/components/notification_manager';

import cloneDeep from 'optly/clone_deep';
import parseQueryParams from 'optly/utils/parse_query_params';
import DesktopAppFns from 'optly/modules/desktop_app/fns';

import TopNavVue from './components/top_nav';

const DialogManagerVueWrapper = getReactWrapperForVueComponent(DialogManager);
const NotificationManagerVueWrapper = getReactWrapperForVueComponent(
  NotifcationManager,
);
const TopNavVueWrapper = getReactWrapperForVueComponent(TopNavVue);

/**
 * Root React Component for /signin page
 */
class OptimizelySignin extends React.Component {
  static displayName = 'OptimizelySignin';

  registerGlobalComponent = (id, instance) => {
    // register the specified component in the global flux store
    flux.dispatch(CoreUIActionTypes.REGISTER_GLOBAL_COMPONENT, {
      id,
      instance,
    });
  };

  createOptimizelyClientGetter = () => {
    let client = null;
    return () => {
      if (client) {
        return client;
      }

      client = createInstance({
        datafile: cloneDeep(config.get('fullstack_datafile')),
      });

      return client;
    };
  };

  static shouldHideNav() {
    const params = parseQueryParams(window.location.href);
    return params.hide_nav !== undefined || DesktopAppFns.isDesktopApp();
  }

  render() {
    const signinRootPanelClasses = DesktopAppFns.isDesktopApp()
      ? ' flex flex--1 height--1-1 flex--column'
      : '';

    return (
      <>
        {/* eslint-disable */}
        <div className="root-wrap height--1-1 width--1-1 flex flex--column overflow-y--auto">
          <Navigation
            logoUrl="/static/img/rebrand/rebrand-logo.svg"
            theme="light"
            helpUrl="https://help.optimizely.com/"
            className="signin-navigation width--1-1"
            title=""
          />
          <div
            id="ouiIconSprite"
            className="display--none"
            dangerouslySetInnerHTML={{ __html: ouiIcons }}
          />
          <IconSprite />
          {DesktopAppFns.isDesktopApp() && (
            <style
              dangerouslySetInnerHTML={{
                __html: `
              /* Hack to make desktop app signin page work with classic CSS. */
              .stage, .stage__item, .stage__item__content, .stage__item__content--column {height: 100% !important;}
            `,
              }}
            />
          )}
          {!OptimizelySignin.shouldHideNav() && <TopNavVueWrapper />}
          <div className={`root-panel${signinRootPanelClasses}`}>
            <RegionComponent regionMap={{}} regionId="main" />
          </div>
        </div>
        <DialogManagerVueWrapper
          vueComponentRef={this.registerGlobalComponent.bind(
            this,
            'dialogManager',
          )}
        />
        <NotificationManagerVueWrapper
          vueComponentRef={this.registerGlobalComponent.bind(
            this,
            'notificationManager',
          )}
        />
        {/* eslint-enable */}
      </>
    );
  }
}

export default OptimizelySignin;
