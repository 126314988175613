import React from 'react';
import PropTypes from 'prop-types';
import { Attention, Link } from 'optimizely-oui';

import AccountActions from 'optly/modules/entity/account/actions';

const PASSWORD_TOKEN_ERRORS = {
  INVALID: 'token_invalid',
  MATCH_FAILED: 'token_match_fail',
  EXPIRED: 'token_expired',
  CLAIMED: 'token_claimed',
};
const PASSWORD_TOKEN_VALID = 'token_valid';

export default class TokenVerification extends React.Component {
  static propTypes = {
    email: PropTypes.string.isRequired,
    setIsTokenError: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isEmailSendingFailed: false,
      failureMessage: '',
      emailSent: false,
      passwordTokenStatus: PASSWORD_TOKEN_VALID,
    };
  }

  componentDidMount = () => {
    this.checkPasswordToken();
  };

  isTokenError = () => {
    const { passwordTokenStatus } = this.state;
    return Object.values(PASSWORD_TOKEN_ERRORS).some(
      val => val === passwordTokenStatus,
    );
  };

  isTokenInvalid = () => {
    const { passwordTokenStatus } = this.state;
    return (
      this.isTokenError() &&
      passwordTokenStatus !== PASSWORD_TOKEN_ERRORS.CLAIMED
    );
  };

  isTokenClaimed = () => {
    const { passwordTokenStatus } = this.state;
    return passwordTokenStatus === PASSWORD_TOKEN_ERRORS.CLAIMED;
  };

  checkPasswordToken = () => {
    const { email, token, setIsTokenError } = this.props;
    if (token === 'None') {
      this.setState(
        { passwordTokenStatus: PASSWORD_TOKEN_ERRORS.INVALID },
        () => setIsTokenError(this.isTokenError()),
      );
      return;
    }
    AccountActions.checkCreatePasswordToken({
      email,
      create_password_token: token,
    }).then(result => {
      this.setState(
        { passwordTokenStatus: result.create_password_token_status },
        () => setIsTokenError(this.isTokenError()),
      );
    });
  };

  onResendTokenClick = () => {
    const { email } = this.props;
    if (!email) {
      return;
    }
    AccountActions.resetPassword(email)
      .then(() => {
        this.setState({
          isEmailSendingFailed: false,
          emailSent: true,
        });
      })
      .fail(err => {
        this.setState({
          isEmailSendingFailed: true,
          failureMessage: Object.prototype.hasOwnProperty.call(
            err,
            'responseText',
          )
            ? tr('{0}', JSON.parse(err.responseText).error)
            : tr('{0}', err.msg),
        });
      });
  };

  render() {
    const { failureMessage, emailSent, isEmailSendingFailed } = this.state;
    return (
      <div>
        {this.isTokenInvalid() && isEmailSendingFailed && (
          <div className="oui-form-bad-news">
            <div
              className="oui-form-note push--bottom"
              data-test-section="create-password-failure-message">
              {failureMessage}
            </div>
          </div>
        )}
        {this.isTokenInvalid() && emailSent && (
          <div className="oui-form-good-news">
            <div
              className="oui-form-note push--bottom"
              data-test-section="create-password-email-resent">
              Email sent! Please check your email for a valid link to create a
              password.
            </div>
          </div>
        )}
        {this.isTokenError() && (
          <Attention
            isDismissible={false}
            testSection="token-error-attention"
            type="bad-news">
            {this.isTokenClaimed() && (
              <div data-test-section="token-claimed">
                Token already claimed. Please <a href="/signin">log in</a> to
                sign in to your Optimizely account.
              </div>
            )}
            {this.isTokenInvalid() && (
              <React.Fragment>
                <p>
                  Token invalid. Please click
                  <Link
                    testSection="create-password-resend-link"
                    onClick={this.onResendTokenClick}>
                    <span className="push-half--left">here</span>
                  </Link>
                  <span className="push-half--left">
                    to re-send an email with a valid link to create a password.
                  </span>
                </p>
              </React.Fragment>
            )}
          </Attention>
        )}
      </div>
    );
  }
}
