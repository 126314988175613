import React from 'react';
import { Button, Input, Link } from 'optimizely-oui';

import { actions as AuthActions } from 'optly/modules/auth';
import handleAjaxError from 'optly/utils/handle_ajax_error';
import LoadingOverlay from 'react_components/loading_overlay';

export default class SigninUser2FA extends React.Component {
  static componentId = 'signin-user-2fa';

  constructor(props) {
    super(props);
    this.state = {
      code: '',
      errorMessage: '',
      isSubmitting: false,
    };
  }

  updateCode = e => this.setState({ code: e.target.value });

  onSubmit = event => {
    event.preventDefault();
    const { code } = this.state;
    if (!code) {
      this.setState({ errorMessage: 'This field is required' });
      return;
    }
    this.setState({
      errorMessage: '',
      isSubmitting: true,
    });
    AuthActions.setTOTPCode(code);
    AuthActions.twoFactorLogin()
      .then(success => {
        this.setState({ isSubmitting: false });
        // When you do a 2fa login, you get a new csrf_token from the server.
        // Let's update this in the app's local state so we can send it in the headers
        // of any subsequent requests; for example, if the user has to reset their password.
        if (success && success.csrf_token) {
          AuthActions.setCSRFToken(success.csrf_token);
        }
        AuthActions.advanceAuthFlow();
      })
      .fail(
        handleAjaxError(message => {
          this.setState({
            errorMessage: message,
            isSubmitting: false,
          });
        }),
      );
  };

  render() {
    const { code, errorMessage, isSubmitting } = this.state;
    return (
      <div className="anchor--middle width--300 push-quad--top--40">
        <LoadingOverlay isLoading={isSubmitting}>
          <form onSubmit={this.onSubmit}>
            <div className="lego-form__header text--center">
              <h2 className="lego-form__title">2-Step Verification</h2>
              <hr className="push-double--ends width--250 anchor--middle" />
            </div>
            <p className="push-double--bottom">
              Welcome back to Optimizely! Please use your authenticator app to
              generate a security code.
            </p>
            <ol className="lego-form-fields">
              <li className="lego-form-field__item">
                <Input
                  value={code}
                  label="Enter your security code:"
                  testSection="2fa-code-input"
                  onChange={this.updateCode}
                  displayError={!!errorMessage}
                  note={errorMessage}
                  focus={true}
                />
              </li>
            </ol>
            <div className="lego-form__footer text--center push-triple--bottom">
              <Button
                isSubmit={true}
                isDisabled={isSubmitting}
                style="highlight"
                testSection="2fa-submit-button">
                Verify
              </Button>
            </div>
            <div className="text--center push--ends">
              <Link
                href="https://support.optimizely.com/hc/en-us/articles/4410284219533-Protect-your-account-with-individual-2-step-verification#Troubleshooting:_Authentication_codes_are_invalid"
                newWindow={true}>
                Problems with your code?
              </Link>
            </div>
          </form>
        </LoadingOverlay>
      </div>
    );
  }
}
