import React from 'react';

import { Attention, Link } from 'optimizely-oui';

import AccountActions from 'optly/modules/entity/account/actions';
import HistoryModule from 'optly/utils/history';
import sanitizeHtml from 'optly/utils/sanitize_html';
import tr from 'optly/translate';
import deparam from 'optly/utils/deparam';

import NewPasswordForm from '../new_password_form';
import TokenVerification from './token_verification';

export default class CreatePassword extends React.Component {
  static componentId = 'create-password';

  constructor(props) {
    super(props);
    const params = deparam.querystring();

    this.state = {
      email: sanitizeHtml(params.email, true),
      passwordToken: sanitizeHtml(params.create_password_token, true),
      collaboratorInviteToken: params.collabToken
        ? sanitizeHtml(params.collabToken, true)
        : '',
      isTokenError: false,
    };
    HistoryModule.replaceState(document.location.pathname, document.title);
  }

  setIsTokenError = value => {
    this.setState({ isTokenError: value });
  };

  doCreatePassword = password => {
    const { passwordToken, collaboratorInviteToken, email } = this.state;
    return new Promise((resolve, reject) => {
      AccountActions.createPassword({
        email,
        password,
        confirm_password: password,
        create_password_token: passwordToken,
        collaborator_invite_token: collaboratorInviteToken,
      })
        .then(result => {
          resolve(result.succeeded);
        })
        .fail(err => {
          reject(
            Object.prototype.hasOwnProperty.call(err, 'responseText')
              ? tr('{0}', JSON.parse(err.responseText).error)
              : tr('{0}', err.msg),
          );
        });
    });
  };

  render() {
    const { email, passwordToken, isTokenError } = this.state;
    return (
      <div
        className="flex flex-justified--center create-password__wrapper signin-background--yellow height--1-1 width--1-1"
        data-test-section="create-password">
        <div className="create-password__container">
          <div className="signin-container signin-container--responsive flex border--all soft-quad">
            <div>
              <h3 data-test-section="create-password-title">
                Create a Password
              </h3>
              <div className="push-triple--ends">
                <TokenVerification
                  email={email}
                  token={passwordToken}
                  setIsTokenError={this.setIsTokenError}
                />
              </div>
              {isTokenError && (
                <Link
                  href="https://help.optimizely.com/hc/en-us/articles/200904580#access"
                  newWindow={true}
                  testSection="create-password-additional-support-link">
                  Additional support and account recovery.
                </Link>
              )}
              {!isTokenError && (
                <div className="push-triple--top">
                  <NewPasswordForm
                    email={email}
                    onSubmit={this.doCreatePassword}
                    submitButtonText="Create Password"
                    testSectionPrefix="create"
                    successMessage="Your password has been created."
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
