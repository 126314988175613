import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';
import { createInstance } from '@optimizely/optimizely-sdk';
import config from 'atomic-config';
import { OktaAuth } from '@okta/okta-auth-js';
import { ProgressDots } from 'optimizely-oui';
import jsCookie from 'js-cookie';

import flux from 'core/flux';
import ui from 'core/ui';
import sandboxRouter from 'core/router';
import cloneDeep from 'optly/clone_deep';
import regex from 'optly/utils/regex';
import { actions as AuthActions } from 'optly/modules/auth';
import deparam from 'optly/utils/deparam';
import { fns as QuotesFns } from 'optly/modules/quotes';
import events from 'optly/services/events';
import { getters as AdminAccountGetters } from 'optly/modules/admin_account';
import { fns as DesktopAppFns } from 'optly/modules/desktop_app';
import { toImmutable } from 'optly/immutable';

import ImpersonateDialog from 'optly/components/dialogs/impersonate';
import CreateAccount from 'optly/components/dialogs/create_account';
import ForgotPassword from 'optly/components/dialogs/forgot_password';

import ChampagneEnums from 'optly/modules/optimizely_champagne/enums';
import AuthEnums from 'optly/modules/auth/enums';

import template from './signin.html';
import { loginWithOptiID } from '../optiid/utils';

const useSeparatePassword = isFeatureEnabled(
  ChampagneEnums.FEATURES.use_separate_email_password_for_login.FEATURE_KEY,
);

const createOptimizelyClientGetter = () => {
  let client = null;
  return () => {
    if (client) {
      return client;
    }
    client = createInstance({
      datafile: cloneDeep(config.get('fullstack_datafile')),
    });
    return client;
  };
};

const getOptimizelyClient = createOptimizelyClientGetter();

const getIsOptiIdEnabled = () => {
  // bypass the feature flag for OAuth login
  const queryParams = deparam.querystring();
  if (
    queryParams.continue_to &&
    queryParams.continue_to.includes('app.optimizely.com/oauth2/authorize')
  ) {
    return true;
  }

  const optimizelyClient = getOptimizelyClient();
  if (optimizelyClient) {
    const optiIdCookie = jsCookie('opti_id_login_enabled');
    const desktopAppCookie = jsCookie('use_desktop_bundle');
    return optimizelyClient.isFeatureEnabled(
      ChampagneEnums.FEATURES.opti_id_switch.FEATURE_KEY,
      'unique_key_for_all',
      {
        optly_config_host_url: config.get('env.HOST_URL', null),
        opti_id_cookie: optiIdCookie !== undefined && optiIdCookie !== '',
        use_desktop_bundle:
          desktopAppCookie !== undefined && desktopAppCookie !== '',
      },
    );
  }
  return false;
};

export default {
  componentId: 'signin',

  template,

  replace: true,

  data: {
    idleLogout: config.get('idle_logout') === 'true',
    showPassword: !useSeparatePassword,
    email: '',
    password: '',
    persist: false,

    missingEmail: false,
    missingPassword: false,
    validEmail: true,
    isInvalid: false,
    errorMessage: '',
    quote: {},
    signinWrapperClasses: '',
    isDesktop: DesktopAppFns.isDesktopApp(),
    isWaitingToLogin: false,
    backgroundImageClass: '',
    isOptiIdLoading: false,
  },

  computed: {
    signupButtonTitle() {
      return tr('Try It Free');
    },
    ssoSigninUrl() {
      return `/signin/sso${deparam.getLocationSearch()}`;
    },
    getIsOptiIdEnabled,
    getForceOptiId() {
      return this.errorMessage.startsWith(
        'Your organization now uses Opti ID.',
      );
    },
  },

  methods: {
    trackModuleClick(moduleName) {
      events.track('Login Page', 'Click', moduleName);
    },
    legacyLoginFallback() {
      this.isWaitingToLogin = false;
      this.showPassword = true;
      this.isOptiIdLoading = false;
    },
    loginWithOptiID() {
      const queryParams = deparam.querystring();
      return loginWithOptiID(this.email, queryParams.continue_to);
    },

    async CheckOpitIdSession() {
      if (!getIsOptiIdEnabled()) return;

      this.isOptiIdLoading = true;
      this.isWaitingToLogin = true;
      let authClient = null;
      try {
        authClient = new OktaAuth({
          client_id: config.get('env.OPTI_ID_CLIENT_ID'),
          issuer: `https://${config.get('env.OPTI_ID_HOST')}`,
        });
      } catch (error) {
        /* eslint-disable-next-line no-console */
        console.warn(
          `[OPTI ID] Could not initialize OktaOauth: ${error.message}`,
        );
        return;
      }

      const session = await authClient.session.get();

      if (session.status === 'INACTIVE') {
        this.isOptiIdLoading = false;
        this.isWaitingToLogin = false;
        return;
      }

      const sessionEmail = session.login;
      const success = await AuthActions.preLogin(sessionEmail);

      if (success.login_type === AuthEnums.LoginOptions.OPTI_ID) {
        try {
          const response = await AuthActions.initiateOptiIDLogin(sessionEmail);
          window.location.href = response.url;
          return;
        } catch (error) {
          // Dont stop the flow
          /* eslint-disable-next-line no-console */
          console.warn(
            `[OPTI ID] Error calling initiate login: ${error.message}`,
          );
          this.isInvalid = true;
          this.errorMessage =
            'The opti id login was unsuccessful. Please try again or use a different login method.';
        }
      }

      this.isOptiIdLoading = false;
      this.isWaitingToLogin = false;
    },

    onSubmit(e) {
      e.preventDefault();

      this.isWaitingToLogin = true;
      this.validateForm();
      // Ensure validation is successful or bail if not.
      if (this.missingEmail || this.missingPassword || !this.validEmail) {
        this.isWaitingToLogin = false;
        return false;
      }

      AuthActions.login({
        email: this.email,
        password: this.password,
        persist: this.persist ? 'on' : 'off', // Support 'remember me' with legacy on/off values
      }).then(
        success => {
          // TODO: If the user has 2fa enabled, they should ONLY be granted a session and CSRF token
          // AFTER they have successfully undergone the second authentication.
          // Therefore, when APP-1062 is implemented, the following logic will need to be updated.
          // See more: https://optimizely.atlassian.net/browse/APP-1062
          AuthActions.setLastSuccessfulAuth(toImmutable(success));

          // if told by backend, initialte sso login
          if (success.sso_login) {
            const loginParams = { email: this.email };
            const queryParams = deparam.querystring();
            if (queryParams.continue_to) {
              loginParams.continue_to = queryParams.continue_to;
            }
            AuthActions.ssoLogin(loginParams).then(
              resp => resp.url && sandboxRouter.windowNavigate(resp.url),
              resp =>
                this.setState({
                  apiError: resp.responseText
                    ? JSON.parse(resp.responseText).error
                    : '',
                }),
            );
            return;
          }

          if (success.requires_password) {
            AuthActions.setEmail(this.email);
          }

          // Save for 2FA enrollment or verification
          if (success.two_factor_token) {
            AuthActions.setEmail(this.email);
            AuthActions.setPassword(this.password);
          }

          // Email needed for display in disabled form input,
          // Password needed for subsequent Account.actions.submitAuthenticatedPasswordChange
          if (success.account_enforced_password_is_expired) {
            AuthActions.setEmail(this.email);
            AuthActions.setPassword(this.password);
          }

          AuthActions.advanceAuthFlow();
        },
        failure => {
          if (failure.responseText) {
            this.errorMessage = JSON.parse(failure.responseText).error;
          }
          this.isInvalid = true;
          this.isWaitingToLogin = false;
        },
      );
    },

    validateForm() {
      this.validateEmptyEmail();
      this.validateEmail();
      this.validatePassword();
    },
    validateEmptyEmail() {
      this.missingEmail = this.email === '';
    },
    validateEmail() {
      this.validEmail = !!regex.email.test(this.email);
    },
    validatePassword() {
      if (useSeparatePassword) {
        this.missingPassword = false;
      } else {
        this.missingPassword = this.password === '';
      }
    },
    showForgotPasswordDialog() {
      ui.showReactDialog(ForgotPassword, {
        props: {
          email: this.email,
        },
      });
    },
    showSignupDialog() {
      ui.showReactDialog(CreateAccount);
    },
    showSingleSignOn(event) {
      event.preventDefault();
      sandboxRouter.go(this.ssoSigninUrl);
    },
    launchImpersonateDialog() {
      ui.showReactDialog(
        ImpersonateDialog,
        {
          props: {
            input: this.input,
          },
        },
        {
          fullScreen: false,
        },
      );
    },
    setSigninWrapperClasses(classValues) {
      this.signinWrapperClasses = classValues;
    },
  },

  created() {
    flux.bindVueValues(this, {
      isAdmin: AdminAccountGetters.isAdmin,
    });

    this.quote = QuotesFns.getRandomQuote();

    if (this.isDesktop) {
      this.setSigninWrapperClasses(
        'height--1-1 p13n-desktop-app flex flex-align--center soft-quad--left',
      );
    } else {
      this.setSigninWrapperClasses(
        'signin-page-container signin-page-container--responsive',
      );
    }

    // Parse the query string
    // Parameter to querystring is whether to coerce it (i.e. "true" => true).
    const urlParams = deparam.querystring(true);

    // Automatically open the signup dialog in case the url has the signup param
    // This addition is needed because there's no other way (that I know of) to show the signup dialog on landing
    if (urlParams.signup) {
      this.showSignupDialog();
    }
  },

  ready() {
    this.CheckOpitIdSession();

    ui.renderReactComponent(this, {
      props: {},
      component: ProgressDots,
      el: this.$$.redirectOverlay,
    });
  },

  attached() {
    // Strangely, `attached` fires multiple times before the `ready` hook even fires.
    // At those times, this.$$ === undefined.
    // Gating this logic fixes it
    if (this.$$ && this.$$.emailInput) {
      this.$$.emailInput.focus();
    }
  },
};
