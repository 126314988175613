import _ from 'lodash';

import constants from './constants';
/**
 * Module specific pure functions
 */

export const getRandomQuote = () => _.sample(constants.quotes);

export default {
  getRandomQuote,
};
