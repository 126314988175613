/**
 * Butterbar service
 * butterbar specific application logic should go here
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
const $ = require('jquery');
const UrlUtil = require('optly/utils/url');

/**
 * @param {string} id
 * @return {Deferred}
 */
function recordSeen(id) {
  return $.ajax({
    url: UrlUtil.optimizelyHRDUrl('/butterbar/seen'),
    type: 'POST',
    data: {
      butterbar_id: id,
    },
  });
}

/**
 * @param {string} id
 * @return {Deferred}
 */
function recordDismissed(id) {
  return $.ajax({
    url: UrlUtil.optimizelyHRDUrl('/butterbar/dismissed'),
    type: 'POST',
    data: {
      butterbar_id: id,
    },
  });
}

/**
 * @return {Deferred}
 */
function getData() {
  return $.ajax({
    url: UrlUtil.optimizelyHRDUrl('/butterbar/get_data'),
    type: 'GET',
  });
}

module.exports = {
  getData,
  recordSeen,
  recordDismissed,
};
