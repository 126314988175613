import $ from 'jquery';

import config from 'atomic-config';

import flux from 'core/flux';
import initializeVue from 'core/ui/methods/initialize_vue';

import router from 'core/router';
import sandbox from 'optly/sandbox';
import authStores from 'optly/modules/auth/stores';
import ui from 'core/ui';
import initializeCoreStores from 'core/initialize';
import PublicApiConsumerConstants from 'optly/modules/public_api_consumer/constants';

import routes from './routes';
import rootVM from './root_vm';

window.jQuery = $;
window.$ = $;
window.$j = $;

flux.registerStores(authStores);
initializeCoreStores();

if (window.optlyConfig) {
  config.set(window.optlyConfig);
}

sandbox.loadInitialData();
initializeVue();
ui.mountRootVM(rootVM, '#root');

// clear any leftover access tokens from the previous session
Object.keys(window.localStorage)
  .filter(localStorageKey =>
    localStorageKey.startsWith(PublicApiConsumerConstants.TOKEN_NAME_PREFIX),
  )
  .forEach(localStorageKey => window.localStorage.removeItem(localStorageKey));

router.initialize();
// load the initial route
router.loadRoutes(routes);
router.go(router.getLocation());

// Detect if oauth token fetch resulted in a logout
if (window.self !== window.top) {
  window.parent.postMessage(
    {
      type: 'OPTLY_IMPLICIT_AUTH_SESSION_EXPIRED',
    },
    `${window.location.origin}`,
  );
}
