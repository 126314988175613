// disabling this rule because it does not make sense to prefer default export here
/* eslint-disable import/prefer-default-export */
import { actions as AuthActions } from 'optly/modules/auth';
import Router from 'core/router';

/**
 * Initiates an OptiID login with the specified email address.
 *
 * @param {string} email - The email address to use for the login.
 * @return {Promise<void>} A promise that resolves when the login process is completed.
 */
export async function loginWithOptiID(email, continue_to = null) {
  const response = await AuthActions.initiateOptiIDLogin(email, continue_to);
  // Navigates the browser to the OptidID URL.
  Router.windowNavigate(response.url);
}
