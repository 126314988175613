import { actions as AuthActions } from 'optly/modules/auth';
/**
 * Function that handles an ajax response from the server.
 *
 * If the request contains a csrf_token field and one is not already on the page,
 * the token will be added.
 *
 * @param {Object} response - The response object from the ajax request
 */
export function handleCSRFResponse(response) {
  const token = response && response.csrf_token;
  if (token) {
    AuthActions.setCSRFToken(token);
  }
}

export default { handleCSRFResponse };
