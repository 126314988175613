import React from 'react';

import { Input, ButtonRow, Button } from 'optimizely-oui';

import PropTypes from 'prop-types';

import ui from 'core/ui';
import router from 'core/router';
import Dialog from 'react_components/dialog';

export default class ImpersonateDialog extends React.Component {
  static propTypes = {
    input: PropTypes.string,
  };

  static defaultProps = {
    input: '',
  };

  constructor(props) {
    super(props);
    const { input } = this.props;
    this.state = {
      inputValue: input,
    };
  }

  handleInputChange = event => {
    this.setState({ inputValue: event.target.value });
  };

  impersonateAccount = () => {
    const { inputValue } = this.state;
    const escapedInput = encodeURIComponent(inputValue);
    const impersonateUrl = `/s/redirect-to-impersonate?input=${escapedInput}`;
    router.windowNavigate(impersonateUrl);
  };

  render() {
    const { inputValue } = this.state;
    return (
      <Dialog.Wrapper testSection="campaign-overview-rename-variation">
        <Dialog.Title>Emulate</Dialog.Title>
        <Dialog.Fieldset>
          <Input
            type="text"
            placeholder="Input: Email, Project Id, Account Id, or Experiment Id"
            testSection="impersonate-input"
            onChange={event => this.handleInputChange(event)}
            value={inputValue}
          />
        </Dialog.Fieldset>
        <Dialog.Footer>
          <ButtonRow
            rightGroup={[
              <Button style="plain" onClick={ui.hideDialog} key="cancel">
                Cancel
              </Button>,
              <Button
                style="highlight"
                onClick={this.impersonateAccount}
                key="emulate"
                testSection="confirm-impersonation">
                Emulate
              </Button>,
            ]}
          />
        </Dialog.Footer>
      </Dialog.Wrapper>
    );
  }
}
