import React from 'react';
import PropTypes from 'prop-types';
import { Attention, Button, Input, Link } from 'optimizely-oui';

import AuthFns from 'optly/modules/auth/fns';
import LoadingOverlay from 'react_components/loading_overlay';

export default class NewPasswordForm extends React.Component {
  static componentId = 'new-password-form';

  static propTypes = {
    email: PropTypes.string.isRequired,
    errorSuffix: PropTypes.node,
    labelPrefix: PropTypes.string,
    // Should be a promise
    onSubmit: PropTypes.func.isRequired,
    submitButtonText: PropTypes.string.isRequired,
    successMessage: PropTypes.string,
    testSectionPrefix: PropTypes.string,
    testSectionPassword: PropTypes.string,
    testSectionConfirmPassword: PropTypes.string,
  };

  static defaultProps = {
    errorSuffix: null,
    testSectionPrefix: '',
    labelPrefix: '',
    successMessage: 'Your password has been updated',
    testSectionPassword: null,
    testSectionConfirmPassword: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      errorMessage: '',
      isSuccess: false,
      password: '',
      confirmPassword: '',
      isSubmitting: false,
      validationErrors: {
        isMissingPassword: false,
        isMissingConfirmPassword: false,
        isPasswordMismatch: false,
        isFailingPasswordPolicy: false,
      },
    };
  }

  validateAndSubmitForm = e => {
    e.preventDefault();
    this.setState(
      prevState => ({
        validationErrors: {
          isMissingPassword: prevState.password === '',
          isMissingConfirmPassword: prevState.confirmPassword === '',
          isPasswordMismatch: prevState.password !== prevState.confirmPassword,
          isFailingPasswordPolicy: !AuthFns.checkComplexPassword(
            prevState.password,
          ).passed,
        },
      }),
      () => {
        const { validationErrors } = this.state;
        if (Object.values(validationErrors).every(val => !val)) {
          this.submitForm();
        }
      },
    );
  };

  submitForm = () => {
    this.setState({ isSubmitting: true });
    const { onSubmit } = this.props;
    const { password } = this.state;
    onSubmit(password).then(
      success => this.setState({ isSuccess: success, isSubmitting: false }),
      errorMessage => this.setState({ errorMessage, isSubmitting: false }),
    );
  };

  getPasswordErrorNote = () => {
    const { validationErrors } = this.state;
    return (
      (validationErrors.isMissingPassword && 'This field is required') ||
      (validationErrors.isFailingPasswordPolicy &&
        'Use at least 8 characters and a mix of upper/lowercase letters, numbers, or symbols') ||
      ''
    );
  };

  getConfirmPasswordErrorNote = () => {
    const { validationErrors } = this.state;
    return (
      (validationErrors.isMissingConfirmPassword && 'This field is required') ||
      (validationErrors.isPasswordMismatch && 'Passwords must match') ||
      ''
    );
  };

  isPasswordValid = () => {
    const { validationErrors } = this.state;
    return !(
      validationErrors.isMissingPassword ||
      validationErrors.isFailingPasswordPolicy
    );
  };

  isConfirmPasswordValid = () => {
    const { validationErrors } = this.state;
    return !(
      validationErrors.isMissingConfirmPassword ||
      validationErrors.isPasswordMismatch
    );
  };

  onPasswordChange = e => this.setState({ password: e.target.value });

  onConfirmPasswordChange = e =>
    this.setState({ confirmPassword: e.target.value });

  getTestSection = testSectionSuffix => {
    const { testSectionPrefix } = this.props;
    return testSectionPrefix ? `${testSectionPrefix}-${testSectionSuffix}` : '';
  };

  render() {
    const {
      errorSuffix,
      email,
      labelPrefix,
      submitButtonText,
      successMessage,
      testSectionPassword,
      testSectionConfirmPassword,
    } = this.props;
    const {
      confirmPassword,
      isSuccess,
      password,
      errorMessage,
      isSubmitting,
    } = this.state;
    return (
      <LoadingOverlay isLoading={isSubmitting}>
        <form autoComplete="off" onSubmit={this.validateAndSubmitForm}>
          <div className="lego-form__header text--center">
            {errorMessage && (
              <div
                className="oui-form-bad-news"
                data-test-section={this.getTestSection('password-error')}>
                <div
                  className="oui-form-note"
                  data-test-section={this.getTestSection('password-fail')}>
                  {errorMessage}
                  {errorSuffix}
                </div>
              </div>
            )}
          </div>
          {isSuccess && (
            <div
              className="oui-text--center"
              data-test-section={this.getTestSection('password-success')}>
              <div className="push-double--bottom">
                <Attention
                  alignment="center"
                  isDismissible={false}
                  testSection={this.getTestSection('password-success-note')}
                  type="good-news">
                  {successMessage}
                </Attention>
              </div>
              <Link
                href="/signin"
                testSection={this.getTestSection('signin-link')}>
                Please log in with your new password.
              </Link>
            </div>
          )}
          {!isSuccess && (
            <div data-test-section={this.getTestSection('password-form')}>
              <ol className="lego-form-fields">
                <li className="lego-form-field__item push-triple--bottom">
                  <Input
                    value={email}
                    type="email"
                    isReadOnly={true}
                    label="Email Address"
                    testSection={this.getTestSection('password-email')}
                  />
                </li>
                <li className="lego-form-field__item push-triple--bottom">
                  <Input
                    value={password}
                    type="password"
                    label={`${labelPrefix} Password`}
                    testSection={
                      testSectionPassword || this.getTestSection('password')
                    }
                    onChange={this.onPasswordChange}
                    displayError={!this.isPasswordValid()}
                    note={this.getPasswordErrorNote()}
                  />
                </li>
                <li className="lego-form-field__item">
                  <Input
                    value={confirmPassword}
                    type="password"
                    label={`Confirm ${labelPrefix} Password`}
                    testSection={
                      testSectionConfirmPassword ||
                      this.getTestSection('confirm-password')
                    }
                    onChange={this.onConfirmPasswordChange}
                    displayError={!this.isConfirmPasswordValid()}
                    note={this.getConfirmPasswordErrorNote()}
                  />
                </li>
              </ol>
              <div className="lego-form__footer">
                <Button
                  isSubmit={true}
                  style="highlight"
                  testSection={this.getTestSection('password-submit')}>
                  {submitButtonText}
                </Button>
              </div>
            </div>
          )}
        </form>
      </LoadingOverlay>
    );
  }
}
