/**
 * Butterbar component
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
const $ = require('jquery');
const flux = require('core/flux');
const AdminAccount = require('optly/modules/admin_account');
const butterbarService = require('optly/services/butterbar');
const tmpl = require('optly/components/butterbar.html');

const WRAPPER_ACTIVE_CLASS = 'butterbar-active';

module.exports = {
  template: tmpl,

  replace: true,

  data: {
    name: null,
    class_name: null,
    html: null,
    dismissable: false,
  },

  methods: {
    dismiss() {
      $(this.$el).hide();
      $(document.body).removeClass(WRAPPER_ACTIVE_CLASS);
      // apparently name is id here
      butterbarService.recordDismissed(this.name);
    },
    /**
     * @param {Object=} data from butterbar service
     */
    _handleButterbarData(data) {
      // load the butterbar model data directly into this.$data
      this.$data = data;

      // butterbar data is available
      if (this.html && this.name) {
        $(this.$el).show();
        $(document.body).addClass(WRAPPER_ACTIVE_CLASS);
        butterbarService.recordSeen(this.name);
      }
    },
  },

  ready() {
    if (flux.evaluate(AdminAccount.getters.isSignedIn)) {
      butterbarService.getData().then(this._handleButterbarData.bind(this));
    }
  },
};
