import React from 'react';
import { Button, Input, Link } from 'optimizely-oui';

import { actions as AuthActions } from 'optly/modules/auth';
import handleAjaxError from 'optly/utils/handle_ajax_error';
import LoadingOverlay from 'react_components/loading_overlay';
import { toImmutable } from 'optly/immutable';

export default class SigninUserPassword extends React.Component {
  static componentId = 'signin-user-password';

  state = {
    password: '',
    errorMessage: '',
    isSubmitting: false,
  };

  updatePassword = e => this.setState({ password: e.target.value });

  onSubmit = event => {
    event.preventDefault();
    const { password } = this.state;
    if (!password) {
      this.setState({ errorMessage: 'This field is required' });
      return;
    }
    this.setState({
      errorMessage: '',
      isSubmitting: true,
    });
    AuthActions.setPassword(password);
    AuthActions.passwordLogin(password)
      .then(success => {
        AuthActions.setLastSuccessfulAuth(toImmutable(success));
        this.setState({ isSubmitting: false });
        AuthActions.advanceAuthFlow();
      })
      .fail(
        handleAjaxError(message => {
          this.setState({
            errorMessage: message,
            isSubmitting: false,
          });
        }),
      );
  };

  render() {
    const { password, errorMessage, isSubmitting } = this.state;
    return (
      <div className="anchor--middle width--300 push-quad--top">
        <LoadingOverlay isLoading={isSubmitting}>
          <form onSubmit={this.onSubmit}>
            <div className="lego-form__header text--center">
              <h2 className="lego-form__title">Password Required</h2>
              <hr className="push-double--ends width--250 anchor--middle" />
            </div>
            <p className="push-double--bottom">
              Welcome back to Optimizely! Please enter your password to continue
              logging in.
            </p>
            <ol className="lego-form-fields">
              <li className="lego-form-field__item">
                <Input
                  value={password}
                  label="Enter your password:"
                  testSection="password-input"
                  onChange={this.updatePassword}
                  displayError={!!errorMessage}
                  note={errorMessage}
                  focus={true}
                  type="password"
                />
              </li>
            </ol>
            <div className="lego-form__footer text--center push-triple--bottom">
              <Button
                isSubmit={true}
                isDisabled={isSubmitting}
                style="highlight"
                testSection="password-submit-button">
                Login
              </Button>
            </div>
            <div className="text--center push--ends">
              <Link href="/signin">Back to user name</Link>
            </div>
            <div className="text--center push--ends">
              <Link
                href="https://help.optimizely.com/hc/en-us/articles/212267578#troubleshoot"
                newWindow={true}>
                Problems with logging in?
              </Link>
            </div>
          </form>
        </LoadingOverlay>
      </div>
    );
  }
}
