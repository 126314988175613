/**
 * Navbar Controller
 *
 * @author Jordan Garcia (jordan@optimizely.com)
 */
import ui from 'core/ui';

import butterbar from 'optly/components/butterbar';

import CreateAccount from 'optly/components/dialogs/create_account';

import template from './top_nav.html';

export default {
  template,

  components: {
    butterbar,
  },

  methods: {
    /**
     * Shows the dialogs/create-account dialog in the header.
     */
    showSignUpDialog() {
      ui.showReactDialog(CreateAccount);
    },
  },
};
