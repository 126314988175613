/* eslint-disable import/no-cycle */
import React from 'react';
import { Input, Button, ButtonRow, Dialog } from 'optimizely-oui';

import ui from 'core/ui';
import keyMirror from 'optly/utils/key_mirror';

import ForgotPassword from 'optly/components/dialogs/forgot_password';
/* eslint-enable import/no-cycle */

const { Wrapper, Title, Footer, Fieldset } = Dialog;

const requestStatuses = keyMirror({
  NOT_SENT: null,
  FAILED: null,
  SUCCEEDED: null,
});

export default class AccountRecoveryRequestDialog extends React.Component {
  state = {
    name: '',
    mail: '',
    comment: '',
    attemptedToSubmit: false,
    requestStatus: requestStatuses.NOT_SENT,
  };

  isNameSet() {
    const { name } = this.state;
    return name !== '';
  }

  isMailSet() {
    const { mail } = this.state;
    return mail !== '';
  }

  isCommentSet() {
    const { comment } = this.state;
    return comment !== '';
  }

  isRequestNotSent() {
    const { requestStatus } = this.state;
    return requestStatus === requestStatuses.NOT_SENT;
  }

  isRequestSucceeded() {
    const { requestStatus } = this.state;
    return requestStatus === requestStatuses.SUCCEEDED;
  }

  isRequestFailed() {
    const { requestStatus } = this.state;
    return requestStatus === requestStatuses.FAILED;
  }

  isValidEmail() {
    const { mail } = this.state;
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(mail) === true;
  }

  showEmailError() {
    const { attemptedToSubmit } = this.state;
    return attemptedToSubmit && !this.isValidEmail();
  }

  launchForgotPasswordDialog = () => {
    // Hide the current dialog to prevent dialogs on dialogs
    ui.hideDialog();
    ui.showReactDialog(ForgotPassword);
  };

  onSubmit = () => {
    const { name, mail, comment } = this.state;

    if (this.isNameSet() && this.isMailSet() && this.isCommentSet()) {
      const url = `/account/signin/support?name=${name}&mail=${mail}&comment=${comment}`;
      if (this.isValidEmail()) {
        fetch(url, {})
          .then(() => {
            this.onSuccess();
          })
          .fail(() => {
            this.onFail();
          });
      } else {
        this.setState({ attemptedToSubmit: true });
      }
    }
  };

  onSuccess() {
    this.setState({ requestStatus: requestStatuses.SUCCEEDED });
  }

  onFail() {
    this.setState({ requestStatus: requestStatuses.FAILED });
  }

  onReset = () => {
    this.setState({
      name: '',
      mail: '',
      comment: '',
    });
  };

  handleNameChange = event => {
    this.setState({ name: event.target.value });
  };

  handleEmailChange = event => {
    this.setState({ mail: event.target.value });
  };

  handleCommentChange = event => {
    this.setState({ comment: event.target.value });
  };

  render() {
    const { name, mail, comment } = this.state;
    return (
      <Wrapper testSection="account-recovery-dialog">
        <Title>Account Recovery Request</Title>
        <p>
          Please fill out this form and a member of the support team will get in
          touch.
        </p>
        <Fieldset>
          {this.isRequestNotSent() && (
            <ol className="oui-form-fields">
              <li className="oui-form-field__item">
                <Input
                  type="text"
                  placeholder="Your name"
                  onChange={event => this.handleNameChange(event)}
                  label="Name:"
                  value={name}
                  testSection="account-recovery-name-input"
                />
              </li>
              <li className="oui-form-field__item">
                <Input
                  type="text"
                  displayError={this.showEmailError() && !this.isValidEmail()}
                  placeholder="Your email"
                  onChange={event => this.handleEmailChange(event)}
                  label="E-mail:"
                  value={mail}
                  testSection="account-recovery-email-input"
                />
                {this.showEmailError() && !this.isValidEmail() && (
                  <div
                    data-test-section="account-recovery-invalid-email"
                    className="oui-form-note oui-form-note--bad-news">
                    Please enter a valid email
                  </div>
                )}
              </li>
              <li className="oui-form-field__item">
                <Input
                  type="text"
                  placeholder="Your comment"
                  onChange={event => this.handleCommentChange(event)}
                  label="What's going wrong when you try to log in?"
                  value={comment}
                  testSection="account-recovery-comment-input"
                />
              </li>
            </ol>
          )}
          {this.isRequestSucceeded() && (
            <div
              className="weight--bold push--ends"
              data-test-section="account-recovery-success">
              Thanks! Someone will be in touch shortly!
            </div>
          )}
          {this.isRequestFailed() && (
            <div
              className="weight--bold push--ends"
              data-test-section="account-recovery-error">
              Something went wrong. Please try again.
            </div>
          )}
        </Fieldset>
        <Footer>
          {this.isRequestNotSent() && (
            <ButtonRow
              leftGroup={[
                <Button
                  style="plain"
                  onClick={this.launchForgotPasswordDialog}
                  key="back">
                  Back
                </Button>,
              ]}
              rightGroup={[
                <Button
                  style="outline-reverse"
                  isDisabled={
                    !this.isNameSet() &&
                    !this.isMailSet() &&
                    !this.isCommentSet()
                  }
                  onClick={this.onReset}
                  key="reset">
                  Reset
                </Button>,
                <Button
                  style="highlight"
                  testSection="account-recovery-submit"
                  isDisabled={
                    !this.isNameSet() ||
                    !this.isMailSet() ||
                    !this.isCommentSet() ||
                    this.showEmailError()
                  }
                  onClick={this.onSubmit}
                  key="submit">
                  Submit
                </Button>,
              ]}
            />
          )}
          {!this.isRequestNotSent() && (
            <ButtonRow
              rightGroup={[
                <Button style="plain" onClick={ui.hideDialog} key="close">
                  Close
                </Button>,
              ]}
            />
          )}
        </Footer>
      </Wrapper>
    );
  }
}
