import React from 'react';

import mailcheck from 'mailcheck';

import classNames from 'classnames';

import { Input, Button, ButtonRow } from 'optimizely-oui';

import ui from 'core/ui';
import deparam from 'optly/utils/deparam';
import {
  Form,
  formPropType,
  handleFormValidationError,
} from 'react_components/form';
import { toImmutable } from 'optly/immutable';
import regex from 'optly/utils/regex';

import events from 'optly/services/events';

import LoadingOverlay from 'react_components/loading_overlay';
import { actions as AuthActions } from 'optly/modules/auth';
import { actions as AccountActions } from 'optly/modules/entity/account';

const FIRST_NAME = 'firstName';
const LAST_NAME = 'lastName';
const EMAIL = 'email';
const PHONE = 'phone';
const COMPANY_NAME = 'companyName';

const dialogTitle = () => tr('Try Optimizely free for 30 days');
const submitButtonCaption = () => 'Start trial';
const isRequiredValidator = val => !val && 'This field is required';

const renderTrialMessage = () => (
  <div className="push-double--top">
    <p>You can get the very best of Optimizely without spending a dime.</p>
    <p className="flush">Try it out for 30 days, on us.</p>
  </div>
);

const renderSuccessMessage = () => (
  <React.Fragment>
    <div className="oui-dialog__header">
      <div className="oui-dialog__title">Success!</div>
      {renderTrialMessage()}
    </div>
    <p className="lego-grid__cell soft-double">
      Success! Please check your email for a link to create a password.
    </p>
    <div className="oui-dialog__footer">
      <ButtonRow
        rightGroup={[
          <Button
            key="goToAccountButton"
            onClick={AuthActions.handleContinueToUrl}
            style="plain">
            Go to your account
          </Button>,
        ]}
      />
    </div>
  </React.Fragment>
);

const renderFailureMessage = errorMessage => (
  <React.Fragment>
    <div className="oui-dialog__header">
      <div className="oui-dialog__title">We're sorry</div>
    </div>
    <p className="lego-grid__cell soft-double--top">
      We're sorry, an error occurred during creation of a new account:
    </p>
    <p className="lego-grid__cell">
      <strong>{errorMessage}</strong>
    </p>
    <p className="lego-grid__cell soft-double--bottom">Please try again.</p>
    <div className="oui-dialog__footer">
      <ButtonRow
        rightGroup={[
          <Button key="closeButton" style="plain" onClick={ui.hideDialog}>
            Close
          </Button>,
        ]}
      />
    </div>
  </React.Fragment>
);

@Form({
  mapPropsToFormData: () =>
    toImmutable({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      companyName: '',
    }),
  validateOnChange: false,
})
class CreateAccount extends React.Component {
  static propTypes = {
    form: formPropType.isRequired,
  };

  constructor(props) {
    super(props);
    this.setupForm(props.form);
    this.state = {
      emailSuggestion: '',
      isFormSubmitted: false,
      isAccountCreatedSuccessfully: false,
      showLoadingOverlay: false,
      serverError: '',
    };
    // Track event
    events.track('/account/create/show');
  }

  setupForm = form => {
    [FIRST_NAME, LAST_NAME, COMPANY_NAME].forEach(fieldId =>
      form.field(fieldId).validators({ isRequired: isRequiredValidator }),
    );
    form.field(PHONE);
    form.field(EMAIL).validators({
      isValid: val => {
        if (!val) {
          return 'This field is required';
        }
        if (!regex.email.test(val)) {
          return 'Valid email address is required ex. <user@example.com>';
        }
      },
    });
  };

  setEmailToSuggestion = () => {
    const { form } = this.props;
    const { emailSuggestion } = this.state;
    form.field(EMAIL).setValue(emailSuggestion);
    this.setState({ emailSuggestion: '' });
  };

  findEmailSuggestion = () => {
    const { form } = this.props;
    mailcheck.run({
      email: form.field(EMAIL).getValue(),
      suggested: suggestion => {
        this.setState({ emailSuggestion: suggestion.full });
      },
      empty: () => {
        this.setState({ emailSuggestion: '' });
      },
    });
  };

  onSubmit = () => {
    const { form } = this.props;
    form
      .validate()
      .then(fieldValues => {
        this.setState({ showLoadingOverlay: true });
        const accountInfo = {
          first_name: fieldValues.get(FIRST_NAME),
          last_name: fieldValues.get(LAST_NAME),
          email: fieldValues.get(EMAIL),
          phone_number: fieldValues.get(PHONE),
          company_name: fieldValues.get(COMPANY_NAME),
          verifyEmail: true,
          'terms-of-service': 'on',
          hidden: 'touched',
          flow: deparam.querystring().flow || 'nux',
        };
        AccountActions.createAccount(accountInfo)
          .then(accountData => {
            this.setState({
              isAccountCreatedSuccessfully: true,
            });
            setTimeout(AuthActions.handleContinueToUrl, 3000);
            const enrichedAccountData = { ...accountData, ...accountInfo };
            return AccountActions.trackSignup(enrichedAccountData);
          })
          .fail(err => {
            const errorMessage = Object.prototype.hasOwnProperty.call(
              err,
              'responseText',
            )
              ? JSON.parse(err.responseText).error
              : err.msg;
            this.setState({
              isAccountCreatedSuccessfully: false,
              serverError: errorMessage,
            });
          })
          .always(() => {
            this.setState({
              isFormSubmitted: true,
              showLoadingOverlay: false,
            });
          });
      })
      .catch(handleFormValidationError);
  };

  renderForm = () => {
    const { form } = this.props;
    const { emailSuggestion } = this.state;
    const firstNameField = form.field(FIRST_NAME);
    const lastNameField = form.field(LAST_NAME);
    const phoneNumberField = form.field(PHONE);
    const companyNameField = form.field(COMPANY_NAME);
    const emailField = form.field(EMAIL);
    return (
      <React.Fragment>
        <div className="oui-dialog__header">
          <div className="oui-dialog__title">{dialogTitle()}</div>
          {renderTrialMessage()}
        </div>
        <ol className="oui-form-fields soft-double">
          <li className="oui-form-field__item">
            <div className="lego-grid">
              <div className="lego-grid__cell">
                <Input
                  type="text"
                  label="First Name"
                  testSection="createAccount-first-name"
                  displayError={firstNameField.getErrors().hasError}
                  isRequired={true}
                  value={firstNameField.getValue()}
                  onChange={e => firstNameField.setValue(e.target.value)}
                  note={firstNameField.getErrors().message}
                />
              </div>
              <div className="lego-grid__cell">
                <Input
                  type="text"
                  label="Last Name"
                  testSection="createAccount-last-name"
                  displayError={lastNameField.getErrors().hasError}
                  isRequired={true}
                  value={lastNameField.getValue()}
                  onChange={e => lastNameField.setValue(e.target.value)}
                  note={lastNameField.getErrors().message}
                />
              </div>
            </div>
          </li>
          <li className="oui-form-field__item">
            <Input
              type="text"
              label="Phone Number"
              testSection="createAccount-phonenumber"
              isOptional={true}
              value={phoneNumberField.getValue()}
              onChange={e => phoneNumberField.setValue(e.target.value)}
            />
          </li>
          <li className="oui-form-field__item">
            <div
              className={classNames({
                'oui-form-bad-news': emailField.getErrors().hasError,
              })}>
              <div className=" oui-label oui-label--required">
                Work Email Address
                {!!emailSuggestion && (
                  <span
                    className="float--right"
                    data-test-section="email-suggestion">
                    Did you mean
                    <a
                      className="push-half--left"
                      onClick={this.setEmailToSuggestion}
                      data-test-section="email-suggestion-value">
                      {emailSuggestion}
                    </a>
                    ?
                  </span>
                )}
              </div>
              <Input
                type="email"
                testSection="createAccount-email"
                displayError={emailField.getErrors().hasError}
                value={emailField.getValue()}
                note={emailField.getErrors().message}
                onChange={e => emailField.setValue(e.target.value)}
                onBlur={this.findEmailSuggestion}
              />
            </div>
          </li>
          <li className="oui-form-field__item">
            <Input
              type="text"
              label="Company"
              testSection="createAccount-companyName"
              isRequired={true}
              displayError={companyNameField.getErrors().hasError}
              onChange={e => companyNameField.setValue(e.target.value)}
              note={companyNameField.getErrors().message}
            />
          </li>
          <li className="oui-form-field__item">
            <p>
              By clicking Start trial, you agree to our
              <a className="push-half--left push-half--right" href="/terms">
                Terms of Service
              </a>
              and
              <a className="push-half--left" href="/privacy">
                Privacy Policy
              </a>
            </p>
          </li>
        </ol>
        <div className="oui-dialog__footer soft-double--sides soft--ends">
          <ButtonRow
            rightGroup={[
              <Button
                key="cancel"
                testSection="createAccount-cancel"
                style="plain"
                onClick={ui.hideDialog}>
                Cancel
              </Button>,
              <Button
                key="submit"
                testSection="createAccount-submit"
                style="highlight"
                onClick={this.onSubmit}>
                {submitButtonCaption()}
              </Button>,
            ]}
          />
        </div>
      </React.Fragment>
    );
  };

  render() {
    const {
      isFormSubmitted,
      isAccountCreatedSuccessfully,
      showLoadingOverlay,
      serverError,
    } = this.state;
    return (
      <LoadingOverlay isLoading={showLoadingOverlay}>
        <div className="oui-dialog">
          {!isFormSubmitted && this.renderForm()}
          {isFormSubmitted &&
            isAccountCreatedSuccessfully &&
            renderSuccessMessage()}
          {isFormSubmitted &&
            !isAccountCreatedSuccessfully &&
            renderFailureMessage(serverError)}
        </div>
      </LoadingOverlay>
    );
  }
}

export default CreateAccount;
