import React from 'react';
import { Button, Link } from 'optimizely-oui';

import ui from 'core/ui';
import AuthActions from 'optly/modules/auth/actions';

import UserConfigure2FA from 'optly/components/dialogs/two_factor/user_configure_2fa';

export default class SigninAccount2FA extends React.Component {
  static componentId = 'signin-account-2fa';

  onClick = () => {
    ui.showReactDialog(UserConfigure2FA, {
      props: {
        enrollAction: () => AuthActions.twoFactorLogin(true),
        onDone: AuthActions.advanceAuthFlow,
        currentView: 'qrCode',
      },
    });
  };

  render() {
    return (
      <div className="anchor--middle width--300 push-quad--top">
        <div className="lego-form__header text--center">
          <h2 className="lego-form__title">2-Step Verification</h2>
          <hr className="push-double--ends width--250 anchor--middle" />
        </div>
        <p>
          Your administrator requires that you set up 2-step verification in
          order to log in to Optimizely.
        </p>
        <p>
          2-step verification increases the security of your Optimizely account.
          When you log in, you’ll need both a secure password and an identifier
          from your phone to access your account.
          <Link
            href="https://help.optimizely.com/hc/en-us/articles/212267578"
            newWindow={true}>
            <span className="push-half--left">Learn more</span>
          </Link>
        </p>

        <div className="lego-form__footer">
          <div className="lego-button-row--center">
            <Button
              onClick={this.onClick}
              style="highlight"
              testSection="signin-account-2fa-button">
              Configure 2-Step Verification
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
