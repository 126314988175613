/**
 * Signin page Routes.
 */
import ui from 'core/ui';
import React from 'react';

import CreatePasswordComp from './components/create_password';
import SetPasswordComp from './components/set_password';
import signinComp from './components/signin';
import SigninUser2FAComp from './components/signin_user_2fa';
import SigninUserPassword from './components/signin_user_password';
import SigninAccount2FAComp from './components/signin_account_2fa';
import SigninSSOComp from './components/signin_sso';

const renderMain = component => () => {
  ui.renderMainRegion({ component });
  ui.loadingStop('main');
};

/**
 * export an array of objects with match<string|RegExp>
 * and handle: <array<function>>
 */
export default [
  {
    // Prompt for a new password; reached via a claim link from Optimizely
    // See main.py ('/claim', recover.IndexHandler)
    match: '/claim',
    handle: [renderMain(<CreatePasswordComp />)],
  },
  {
    // Prompt for a new password; reached via a claim link from Optimizely
    // See main.py ('/account/create_password', recover.IndexHandler)
    match: '/accounts/create_password',
    handle: [renderMain(<CreatePasswordComp />)],
  },
  {
    // Prompt for a new password; reached when org-enforced 90-day
    // expiration is triggered on the user's account
    // Frontend route only
    match: '/password_expired',
    handle: [renderMain(<SetPasswordComp path="password_expired" />)],
  },
  {
    // Prompt for a new password; reached via a recovery email
    // See main.py ('/recover', recover.IndexHandler)
    match: '/recover',
    handle: [renderMain(<SetPasswordComp path="recover" />)],
  },
  {
    // See main.py ('/signin(?:.*)', account.SigninPageHandler)
    match: '/signin',
    handle: [renderMain(signinComp)],
  },
  {
    // Handle user-level 2-step verification configuration when account
    // admin requires it
    // See main.py ('/signin(?:.*)', account.SigninPageHandler)
    match: '/signin/configure',
    handle: [renderMain(<SigninAccount2FAComp />)],
  },
  {
    // Handle single sign on
    // See main.py ('/signin/sso', account.SsoSigninPageHandler)
    match: '/signin/sso',
    handle: [renderMain(<SigninSSOComp />)],
  },
  {
    // Handle user-level 2-step verification signin
    // See main.py ('/signin(?:.*)', account.SigninPageHandler)
    match: '/signin/verify',
    handle: [renderMain(<SigninUser2FAComp />)],
  },
  {
    // Handle user-level 2-step verification signin
    // See main.py ('/signin(?:.*)', account.SigninPageHandler)
    match: '/signin/password',
    handle: [renderMain(<SigninUserPassword />)],
  },
  {
    match: '*',
    handle: [
      // catchall no-op
      function() {},
    ],
  },
];
