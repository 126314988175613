import React from 'react';
import { Link, Input, Dialog, ButtonRow, Button } from 'optimizely-oui';

import PropTypes from 'prop-types';

import { QRCodeSVG } from 'qrcode.react';

import ui from 'core/ui';
import {
  actions as UserActions,
  getters as UserGetters,
} from 'optly/modules/entity/user';
import { connect } from 'core/ui/decorators';
import Immutable from 'optly/immutable';
import { handleCSRFResponse } from 'optly/utils/handle_csrf_response';
import handleAjaxError from 'optly/utils/handle_ajax_error';
import keyMirror from 'optly/utils/key_mirror';
import LoadingOverlay from 'react_components/loading_overlay';
import { actions as AuthActions } from 'optly/modules/auth';

import BackupCodesComponent from 'optly/components/dialogs/two_factor/backup_codes';

const { Title } = Dialog;

const Views = keyMirror({
  backupCodes: 'backupCodes',
  password: 'password',
  qrCode: 'qrCode',
  securityCode: 'securityCode',
});

@connect({
  currentUser: UserGetters.currentUser,
})
class UserConfigure2fa extends React.Component {
  static propTypes = {
    currentUser: PropTypes.instanceOf(Immutable.Map).isRequired,
    currentView: PropTypes.string,
    dialogText: PropTypes.string,
    dialogTitle: PropTypes.string,
    enrollAction: PropTypes.func.isRequired,
    onDone: PropTypes.func,
  };

  static defaultProps = {
    dialogTitle: tr('Enable 2-Step Verification'),
    currentView: Views.password,
    dialogText: tr(
      '2-step verification increases the security of \
      your Optimizely account. When you sign in, you’ll need both a \
      secure password and an identifier from your phone to access your \
      account. <a href="https://support.optimizely.com/hc/en-us/articles/4410284219533-Protect-your-account-with-individual-2-step-verification" \
      target="_blank">Learn more.</a>',
    ),
    onDone: null,
  };

  constructor(props) {
    super(props);
    const { currentView } = this.props;
    this.state = {
      twoFactorCode: '',
      code: null,
      currentView,
      backupCodes: [],
      password: '',
      errorMessage: null,
    };
  }

  componentDidMount() {
    // Get the QR code from the Auth module
    AuthActions.generateTwoFactorSecret().then(response => {
      // Call setNewTemp2FASecret
      if (response.temp_2fa_code) {
        AuthActions.setTemp2faSecret(response.temp_2fa_code);
      }
      this.setState({ code: response.qr_code_path });
    });
  }

  backText() {
    const { currentView } = this.state;
    if (currentView === Views.password) {
      return tr('Cancel');
    }
    return tr('Back');
  }

  nextText() {
    const { currentView } = this.state;
    if (currentView === Views.backupCodes) {
      return tr('Done');
    }
    return tr('Next');
  }

  onNext = () => {
    const { onDone } = this.props;
    const { currentView } = this.state;
    if (currentView === Views.password) {
      this.submitPassword();
    } else if (currentView === Views.qrCode) {
      this.setState({ currentView: Views.securityCode });
    } else if (currentView === Views.securityCode) {
      this.enrollUser();
    } else if (currentView === Views.backupCodes) {
      ui.showNotification({
        message: tr('2-step verification enabled successfully.'),
        type: 'success',
      });
      if (onDone) {
        onDone();
      }
      ui.hideDialog();
    }
  };

  onBack = () => {
    const { currentView } = this.state;
    if (currentView === Views.password) {
      ui.hideDialog();
    } else if (currentView === Views.qrCode) {
      this.setState({ currentView: Views.password });
    } else if (currentView === Views.securityCode) {
      this.setState({ errorMessage: '' });
      this.setState({ currentView: Views.qrCode });
    }
  };

  submitPassword() {
    const { password } = this.state;
    this.setState({ errorMessage: null });

    if (!password) {
      this.setState({ errorMessage: tr('Please enter a password') });
    } else {
      ui.loadingStart('password');
      AuthActions.validatePassword(password)
        .then(response => {
          ui.loadingStop('password');
          if (response.password_status) {
            AuthActions.setPassword(password);
            this.setState({ currentView: Views.qrCode });
          } else {
            this.setState({
              errorMessage: tr('The password you entered is incorrect.'),
            });
          }
        })
        .fail(
          handleAjaxError(() => {
            ui.loadingStop('password');
            this.setState({
              errorMessage: tr('The password you entered is incorrect.'),
            });
          }),
        );
    }
  }

  showBack() {
    const { currentView } = this.state;
    return currentView !== Views.backupCodes;
  }

  enrollUser = () => {
    const { twoFactorCode } = this.state;
    const { currentUser, enrollAction } = this.props;
    if (!twoFactorCode) {
      this.setState({ errorMessage: tr('This field is required.') });
    } else {
      this.setState({ errorMessage: null });
      const errorMessageText = tr(
        'The security code you entered is incorrect.',
      );
      AuthActions.setTOTPCode(twoFactorCode);
      ui.loadingWhen(
        'security-code-loading',
        enrollAction()
          .then(response => {
            if (!response.succeeded) {
              this.setState({ errorMessage: errorMessageText });
            } else {
              handleCSRFResponse(response);
              this.setState({ currentView: Views.backupCodes });
              AuthActions.getBackupCodes().then(result => {
                this.setState({ backupCodes: result.backup_codes });
              });
              if (currentUser && currentUser.get('id')) {
                UserActions.fetch(currentUser.get('id'), true);
              }
            }
          })
          .fail(
            handleAjaxError(() => {
              this.setState({ errorMessage: errorMessageText });
            }),
          ),
      );
    }
  };

  renderPasswordComponent() {
    const { password, errorMessage } = this.state;
    const { dialogTitle, dialogText } = this.props;
    return (
      <div>
        <Title>{dialogTitle}</Title>
        <p dangerouslySetInnerHTML={{ __html: dialogText }} />
        <LoadingOverlay size="small" loadingId="password">
          <ol className="oui-form-fields">
            <li className="oui-form-field__item">
              <Input
                type="password"
                onChange={event =>
                  this.setState({ password: event.target.value })
                }
                value={password}
                testSection="password-input"
                label="Please re-enter your Optimizely password:"
                note={errorMessage}
                displayError={errorMessage}
              />
            </li>
          </ol>
        </LoadingOverlay>
      </div>
    );
  }

  renderQrCodeComponent() {
    const { code } = this.state;
    return (
      <div data-test-section="qr-code-section" className="push-double--bottom">
        <h1>Scan the QR Code</h1>
        <p className="push--bottom">
          You’ll need an authenticator app to generate and manage your security
          codes. If you don’t have one,
          <Link
            className="push-half--left"
            newWindow={true}
            href="https://support.optimizely.com/hc/en-us/articles/4410284219533#Enable_individual_2-step_verification">
            try one of these popular apps
          </Link>
        </p>
        {code && (
          <QRCodeSVG
            data-test-section="qr-image"
            className="anchor--middle width--200 display--block"
            value={code}
          />
        )}
      </div>
    );
  }

  renderBackupCodes() {
    const { backupCodes } = this.state;
    return <BackupCodesComponent backupCodes={backupCodes} />;
  }

  renderSecurityCodeComponent() {
    const { twoFactorCode, errorMessage } = this.state;
    return (
      <div>
        <Title>Enter Your Security Code</Title>
        <LoadingOverlay size="small" loadingId="security-code-loading">
          <ol className="oui-form-fields">
            <li className="oui-form-field__item">
              <Input
                type="text"
                onChange={event =>
                  this.setState({ twoFactorCode: event.target.value })
                }
                value={twoFactorCode}
                testSection="security-code-input"
                label="Enter the security code generated by your authenticator app:"
                note={errorMessage}
                displayError={errorMessage}
              />
            </li>
          </ol>
        </LoadingOverlay>
      </div>
    );
  }

  render() {
    const { currentView } = this.state;
    return (
      <div
        className="flex--1 push--bottom push-double--top"
        data-test-section="two-factor-configure-dialog">
        <div className="reading-column flush--top flush--bottom">
          {currentView === Views.password && this.renderPasswordComponent()}
          {currentView === Views.qrCode && this.renderQrCodeComponent()}
          {currentView === Views.securityCode &&
            this.renderSecurityCodeComponent()}
          {currentView === Views.backupCodes && this.renderBackupCodes()}
          <div className="push-double--top">
            <ButtonRow
              rightGroup={[
                this.showBack() && (
                  <Button
                    style="plain"
                    key="back"
                    testSection="back-button"
                    onClick={this.onBack}>
                    {this.backText()}
                  </Button>
                ),
                <Button
                  testSection="next-button"
                  style="highlight"
                  key="next"
                  onClick={this.onNext}>
                  {this.nextText()}
                </Button>,
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default UserConfigure2fa;
