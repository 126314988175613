import React from 'react';
import PropTypes from 'prop-types';
import { Attention, Link as InternalLink } from 'optimizely-oui';

import { connect } from 'core/ui/decorators';
import ui from 'core/ui';
import HistoryModule from 'optly/utils/history';
import { getters as AuthGetters } from 'optly/modules/auth';
import { actions as AccountActions } from 'optly/modules/entity/account';
import deparam from 'optly/utils/deparam';

import ForgotPassword from 'optly/components/dialogs/forgot_password';

import NewPasswordForm from '../new_password_form';

const PATH_PASSWORD_EXPIRED = 'password_expired';
const PATH_RECOVER = 'recover';
const showForgotPasswordDialog = () => ui.showReactDialog(ForgotPassword);
const renderShowForgotDialogMessage = () => (
  <div>
    Please
    <InternalLink onClick={showForgotPasswordDialog}>
      <span className="push-half--left">click here</span>
    </InternalLink>
    <span className="push-half--left">to generate a new token.</span>
  </div>
);

@connect(props => {
  const { path } = props;
  return {
    email:
      (path === PATH_PASSWORD_EXPIRED && AuthGetters.email) ||
      AuthGetters.recoverEmail,
    oldPassword: (path === PATH_PASSWORD_EXPIRED && AuthGetters.password) || [
      () => '',
    ],
  };
})
class SetPassword extends React.Component {
  static componentId = 'set-password';

  static propTypes = {
    email: PropTypes.string.isRequired,
    oldPassword: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.token =
      (props.path === PATH_RECOVER && deparam.querystring().token) || '';
    // These are the fallback error messages if server returns empty error message.
    // These seem to be nothing more than a best guess of what the error cause was.
    // No guarantees that the given reason was truly the cause.
    this.fallbackFailureMessage =
      (props.path === PATH_PASSWORD_EXPIRED &&
        tr(
          'Password could not be changed. Please refresh the page and try again.',
        )) ||
      tr(
        'Password could not be changed. The recovery token is no longer valid.',
      );
  }

  componentDidMount = () => {
    const { path } = this.props;
    HistoryModule.replaceState(`/${path}`, document.title);
  };

  updatePassword = password => {
    const { email, oldPassword, path } = this.props;
    return new Promise((resolve, reject) => {
      const submitAction =
        path === PATH_PASSWORD_EXPIRED
          ? AccountActions.submitAuthenticatedPasswordChange({
              old_password: oldPassword,
              new_password1: password,
              new_password2: password,
            })
          : AccountActions.setPassword({
              email,
              token: this.token,
              new_password1: password,
              new_password2: password,
              new_account: false,
            });
      submitAction
        .then(() => resolve(true))
        .fail(err => {
          const msg = Object.prototype.hasOwnProperty.call(err, 'responseText')
            ? tr(
                '{0}',
                JSON.parse(err.responseText).error ||
                  JSON.parse(err.responseText).msg,
              )
            : tr('{0}', err.msg);
          reject(msg || this.fallbackFailureMessage);
        });
    });
  };

  render() {
    const { email, path } = this.props;
    return (
      <div className="anchor--middle width--300 push-quad--top">
        <div
          className="lego-form__header text--center lego-form__title"
          data-test-section="set-password-title">
          Password {(path === PATH_PASSWORD_EXPIRED && 'Expired') || 'Recovery'}
        </div>
        {path === PATH_PASSWORD_EXPIRED && (
          <p className="text--center">
            {tr('Your password has expired. Please choose a new password.')}
          </p>
        )}
        {path === PATH_RECOVER && !email && (
          <Attention
            alignment="center"
            isDismissible={false}
            testSection="token-error-attention"
            type="bad-news">
            <div>The recovery token is either expired or invalid.</div>
            {renderShowForgotDialogMessage()}
          </Attention>
        )}
        {(path === PATH_PASSWORD_EXPIRED || email) && (
          <NewPasswordForm
            errorSuffix={renderShowForgotDialogMessage()}
            email={email}
            onSubmit={this.updatePassword}
            submitButtonText={tr('Change Password')}
            testSectionPrefix="recover"
            labelPrefix="New"
            successMessage="Your password has been changed."
            testSectionPassword="new_password1"
            testSectionConfirmPassword="new_password2"
          />
        )}
      </div>
    );
  }
}

export default SetPassword;
