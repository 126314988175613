/* eslint-disable import/no-cycle */
import React from 'react';

import { Input, Button, Link, ButtonRow, Dialog } from 'optimizely-oui';

import ui from 'core/ui';
import { toImmutable } from 'optly/immutable';
import regex from 'optly/utils/regex';
import { Form, formPropType } from 'react_components/form';
import { actions as AccountActions } from 'optly/modules/entity/account';
import AccountRecoveryRequest from 'optly/components/dialogs/account_recovery_request';
/* eslint-enable import/no-cycle */

const { Wrapper, Title, Footer, Fieldset } = Dialog;

@Form({
  mapPropsToFormData: props =>
    toImmutable({
      emailAddress: props.email || '',
    }),
  validateOnChange: false,
})
class ForgotPasswordDialog extends React.Component {
  static propTypes = {
    form: formPropType.isRequired,
  };

  constructor(props) {
    super(props);
    const { form } = props;

    this.state = {
      failure: false,
      emailSent: false,
    };

    form.field('emailAddress').validators({
      validateEmailFormat: val => {
        if (!regex.email.test(val.trim())) {
          return 'Valid email address is required ex. <user@example.com>';
        }
      },
      validateEmptyEmail: val => {
        if (!val) {
          return 'This field is required';
        }
      },
    });
  }

  launchAccountRequestRecovery = () => {
    // Hide the current dialog to prevent dialogs on dialogs
    ui.hideDialog();
    ui.showReactDialog(AccountRecoveryRequest);
  };

  onSubmit = () => {
    const { form } = this.props;
    form.validate().then(() => {
      const formData = form.getValue();
      AccountActions.resetPassword(formData.get('emailAddress').trim()).then(
        this.handleSuccess,
        this.handleError,
      );
    });
  };

  handleSuccess = () => {
    this.setState({
      failure: false,
      emailSent: true,
    });
  };

  handleError = () => {
    this.setState({ failure: true });
  };

  render() {
    const { form } = this.props;
    const emailAddress = form.field('emailAddress');
    const { emailSent, failure } = this.state;
    return (
      <Wrapper>
        <Title>Forgot Your Password</Title>
        <Fieldset>
          <ol className="oui-form-fields">
            {!emailSent && (
              <li className="oui-form-field__item push-half--bottom">
                <p>
                  We'll send you an email with a link to reset your password
                </p>
              </li>
            )}
            {!emailSent && (
              <li className="oui-form-field__item">
                <Input
                  testSection="forgot-password-email"
                  displayError={emailAddress.getErrors().hasError}
                  type="text"
                  note={emailAddress.getErrors().message}
                  onChange={event => emailAddress.setValue(event.target.value)}
                  label="Email address"
                  value={emailAddress.getValue()}
                />
              </li>
            )}
            {emailSent && (
              <div className="color--good-news" data-test-section="email-sent">
                Email has been sent
              </div>
            )}
            {failure && (
              <div
                className="color--bad-news"
                data-test-section="account-not-found">
                Account was not found
              </div>
            )}
            <li className="oui-form-field__item push-half--bottom">
              <p>
                <Link
                  href="https://help.optimizely.com/hc/en-us/articles/200904580#access"
                  newWindow={true}>
                  Additional support and account recovery
                </Link>
              </p>
              <p>
                Still can't log in?
                <Button
                  style="plain"
                  testSection="signin-links-register"
                  onClick={this.launchAccountRequestRecovery}>
                  Request help
                </Button>
              </p>
            </li>
          </ol>
        </Fieldset>
        <Footer>
          {!emailSent ? (
            <ButtonRow
              rightGroup={[
                <Button onClick={ui.hideDialog} style="plain" key="cancel">
                  Cancel
                </Button>,
                <Button
                  style="highlight"
                  testSection="forgot-password-submit"
                  onClick={this.onSubmit}
                  key="submit">
                  Send Email
                </Button>,
              ]}
            />
          ) : (
            <ButtonRow
              rightGroup={[
                <Button
                  onClick={ui.hideDialog}
                  testSection="done-button"
                  style="plain"
                  key="done">
                  Done
                </Button>,
              ]}
            />
          )}
        </Footer>
      </Wrapper>
    );
  }
}

export default ForgotPasswordDialog;
