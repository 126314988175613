import React from 'react';
import PropTypes from 'prop-types';
import { Attention, Dialog } from 'optimizely-oui';

const { Title } = Dialog;

export default class BackupCodes extends React.Component {
  static propTypes = {
    backupCodes: PropTypes.array.isRequired,
    shouldShowMessage: PropTypes.bool,
  };

  static defaultProps = {
    shouldShowMessage: false,
  };

  listBackupCodes() {
    const { backupCodes } = this.props;
    return backupCodes.map(backupCode => (
      <li data-test-section="backup-code-row">{backupCode}</li>
    ));
  }

  render() {
    const { shouldShowMessage } = this.props;
    return (
      <div data-test-section="backup-code-section">
        <h1>Save Your Backup Codes</h1>
        <p>
          If you lose your phone or can’t access your authenticator app, you can
          use a backup code for one-time access into your account. Please write
          down these codes and store them in a safe place.
        </p>
        {shouldShowMessage && (
          <Attention type="good-news">
            Your new codes are below. The old codes are now invalid and cannot
            be used to access your account.
          </Attention>
        )}
        <ul className="monospace text--center push-double--top">
          {this.listBackupCodes()}
        </ul>
      </div>
    );
  }
}
