/**
 * Quotes that show on the signin page for the p13n Desktop app.
 * The `nums` are randomly generated for the `From the Optiverse
 * Archives No. ##` string. (It's just for show).
 */
export default {
  quotes: [
    {
      text:
        'The Optimizely snippet is one line of code that you add to your \
        site to make Optimizely work.',
      num: 1631,
    },
    {
      text:
        'Add the snippet as high up in the head tag as possible, on every \
      page where an experiment will run or track goals.',
      num: 2791,
    },
    {
      text:
        'Use the Diagnostic Report feature to make sure the snippet is \
      implemented correctly.',
      num: 23,
    },
    {
      text:
        'Hypothesis-driven testing means that you start by identifying what \
      experience you want to change and how it will affect your business \
      results -- not just changing things and hoping for the best.',
      num: 105,
    },
    {
      text:
        'Your early tests should increase focus on your Call to Action (CTA \
      or remove distractions).',
      num: 88,
    },
    {
      text:
        'Seek the global maximum: Test the types of changes that are more \
      likely to affect your overall conversion goals, not small tweaks.',
      num: 371,
    },
    {
      text:
        'Identify the high-value pages that you have on your site, and how \
      each of them affects your ultimate conversion goal.',
      num: 235,
    },
    {
      text:
        'More insightful tests ask "why do we want to make changes?" \
      instead of "what should we change?"',
      num: 75,
    },
    {
      text:
        'Conceptual validation is a technique to test different (sometimes \
      radical) concepts to validate moving in a direction, by creating a rough \
      prototype of the concept.',
      num: 154,
    },
    {
      text:
        'Multivariate tests combine multiple A/B tests to let you compare \
      different groupings of variations in many combinations.',
      num: 2025,
    },
    {
      text:
        'Multi-page funnel tests let you make several changes to a group of \
      pages, like a checkout funnel, and measure the overall impact of changing the experience.',
      num: 7,
    },
    {
      text:
        'Create visitor personas and use them to analyze how tests perform \
      with different audience segments, rather than just measuring overall performance.',
      num: 166,
    },
  ],
};
