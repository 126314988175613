import React from 'react';
import { createInstance } from '@optimizely/optimizely-sdk';
import config from 'atomic-config';
import jsCookie from 'js-cookie';

import { Button, Input, Link, Icon as AxiomIcon } from 'optimizely-oui';

import Router from 'core/router';
import cloneDeep from 'optly/clone_deep';
import regex from 'optly/utils/regex';
import deparam from 'optly/utils/deparam';
// import sanitizeHtml from 'optly/utils/sanitize_html';

import { actions as AuthActions } from 'optly/modules/auth';
import { fns as DesktopAppFns } from 'optly/modules/desktop_app';
import ChampagneEnums from 'optly/modules/optimizely_champagne/enums';

import { loginWithOptiID } from '../../optiid/utils';

const LEARN_MORE_SSO_LINK =
  'https://help.optimizely.com/hc/en-us/articles/210288167#sso';
const showSignIn = () =>
  Router.go(
    DesktopAppFns.isDesktopApp()
      ? '/signin?continue_to=/p13n'
      : `/signin${deparam.getLocationSearch()}`,
  );
export default class SigninSSO extends React.Component {
  static componentId = 'signin-sso';

  state = {
    email: '',
    isEmailInvalid: false,
    isEmailMissing: false,
    apiError: '',
    isOptidIDEnabled: false,
  };

  componentDidMount = () => {
    // sso_error is passed from the server to display the corresponding error to the user
    const queryStringParams = deparam.querystring();
    const enabled = this.getIsOptiIdEnabled();

    this.setState({
      apiError: queryStringParams.sso_error,
      isOptidIDEnabled: enabled,
    });
  };

  getIsOptiIdEnabled = () => {
    const getOptimizelyClient = this.createOptimizelyClientGetter();
    const optimizelyClient = getOptimizelyClient();
    if (optimizelyClient) {
      const optiIdCookie = jsCookie('opti_id_login_enabled');
      const desktopAppCookie = jsCookie('use_desktop_bundle');
      return optimizelyClient.isFeatureEnabled(
        ChampagneEnums.FEATURES.opti_id_switch.FEATURE_KEY,
        'unique_key_for_all',
        {
          optly_config_host_url: config.get('env.HOST_URL', null),
          opti_id_cookie: optiIdCookie !== undefined && optiIdCookie !== '',
          use_desktop_bundle:
            desktopAppCookie !== undefined && desktopAppCookie !== '',
        },
      );
    }
    return false;
  };
  /* eslint-disable */
  createOptimizelyClientGetter = () => {
    let client = null;
    return () => {
      if (client) {
        return client;
      }

      client = createInstance({
        datafile: cloneDeep(config.get('fullstack_datafile')),
      });

      return client;
    };
  };
  /* eslint-enable */

  loginWithOptiID = () => {
    const { email } = this.state;
    const queryParams = deparam.querystring();
    return loginWithOptiID(email, queryParams.continue_to);
  };

  submitForm = () => {
    const { email } = this.state;
    const loginParams = { email };
    const queryParams = deparam.querystring();
    if (queryParams.continue_to) {
      loginParams.continue_to = queryParams.continue_to;
    }
    AuthActions.ssoLogin(loginParams).then(
      resp => resp.url && Router.windowNavigate(resp.url),
      resp =>
        this.setState({
          apiError: resp.responseText
            ? JSON.parse(resp.responseText).error
            : '',
        }),
    );
  };

  validateAndSubmitForm = e => {
    e.preventDefault();
    this.setState(
      prevState => ({
        isEmailMissing: prevState.email === '',
        isEmailInvalid: !regex.email.test(prevState.email),
        apiError: '',
      }),
      () => {
        const { isEmailMissing, isEmailInvalid } = this.state;
        if (!isEmailMissing && !isEmailInvalid) {
          this.submitForm();
        }
      },
    );
  };

  getEmailError = () => {
    const { isEmailMissing, isEmailInvalid } = this.state;
    return (
      (isEmailMissing && 'This field is required') ||
      (isEmailInvalid &&
        'Valid email address is required ex. <user@example.com>') ||
      ''
    );
  };

  renderForm = () => {
    const {
      email,
      isEmailInvalid,
      isEmailMissing,
      apiError,
      isOptidIDEnabled,
    } = this.state;
    return (
      <form
        className="flex flex--column height--1-1"
        onSubmit={this.validateAndSubmitForm}>
        <div>
          <h1 className="gamma push-triple--bottom">Single Sign-On</h1>

          {apiError && apiError.includes('login.optimizely.com') ? (
            <div
              className="oui-form-bad-news"
              data-test-section="signin-sso-form-error-message">
              <span className="oui-form-note">
                Your organization now uses Opti ID. Please sign-in at{' '}
                <a href="https://login.optimizely.com">login.optimizely.com</a>.
              </span>
            </div>
          ) : (
            <div
              className="oui-form-bad-news"
              data-test-section="signin-sso-form-error-message">
              <span className="oui-form-note">{apiError}</span>
            </div>
          )}
        </div>
        <ol className="oui-form-fields">
          <li>
            <Input
              id="email"
              value={email}
              type="text"
              label="Email"
              focus={true}
              testSection="signinsso-email"
              onChange={event => {
                this.setState({ email: event.target.value });
              }}
              displayError={isEmailMissing || isEmailInvalid}
              note={this.getEmailError()}
            />
          </li>
        </ol>
        <div className="lego-form__footer push-double--bottom">
          <Button
            isSubmit={true}
            style="highlight"
            testSection="signinsso-submit">
            Log In
          </Button>
        </div>
        <div className="flex flex--column flex-justified--between flex-grow-1">
          <ul>
            {/* eslint-disable jsx-a11y/anchor-is-valid */}
            <li>
              <Link
                variant="default"
                onClick={showSignIn}
                testSection="signinsso-switch">
                Log in with email &amp; password
              </Link>
            </li>
            {(isOptidIDEnabled || DesktopAppFns.isDesktopApp()) && (
              <li>
                <Link
                  variant="default"
                  onClick={this.loginWithOptiID}
                  testSection="signin-with-opti-id-btn">
                  Log in using Opti ID
                </Link>
              </li>
            )}
            {/* eslint-enable jsx-a11y/anchor-is-valid */}
          </ul>
          <p>
            Learn more about
            <Link href={LEARN_MORE_SSO_LINK} newWindow={true}>
              <span className="push-half--left">single sign-on</span>
              <span className="push-half--left">
                <AxiomIcon
                  color="color--base"
                  name="arrow-up-right-from-square"
                  size="small"
                  data-test-section="axiom-icon-arrow-up"
                />
              </span>
            </Link>
          </p>
        </div>
      </form>
    );
  };

  rebrand2021SSO = () => {
    return (
      <div className="signin-page-container signin-page-container--responsive">
        <div className="lego-grid lego-grid--flush">
          <div className="flex flex--column width--1-1">
            <div className="flex border--all signin-container signin-container--responsive">
              <div className="signin-form lego-grid__cell flex flex--column signin-form--responsive signin-form--no-background">
                {this.renderForm()}
              </div>
              <div
                className="signin-module-container soft-triple--right soft-triple--left soft-quad--top soft-quad--bottom signin-module-container--no-background signin-module-container--responsive"
                data-test-section="signinSSO2021-right-content">
                <a
                  href="https://www.optimizely.com/resources/building-an-experimentation-program/?utm_content=WebPromo-Login"
                  id="signin-module--1"
                  className="signin-module signin-module--1 flex flex--column signin-module--1-responsive"
                  rel="noopener noreferrer"
                  target="_blank">
                  <span className="display--none">get the book</span>
                </a>
                <div className="flex signin-module__extra-content">
                  <a
                    href="http://pages.optimizely.com/guide-statistics-online-experiments.html?utm_source=optimizely&amp;utm_medium=website&amp;utm_content=white_paper_stats_engine&amp;utm_campaign=LI_loginpage"
                    id="signin-module--2"
                    className="signin-module signin-module--small signin-module--2 signin-module--responsive"
                    rel="noopener noreferrer"
                    target="_blank">
                    <span className="display--none">get the book</span>
                  </a>
                  <a
                    href="https://www.optimizely.com/case-studies/citrix/?utm_source=optimizely&amp;utm_medium=website&amp;utm_content=case_study_citrix&amp;utm_campaign=LI_loginpage"
                    id="signin-module--3"
                    className="signin-module signin-module--3 signin-module--small signin-module--responsive"
                    rel="noopener noreferrer"
                    target="_blank">
                    <span className="display--none">get the book</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="flex--row text--center push-double--top signin-footer">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.optimizely.com/terms/"
                data-test-section="terms-link">
                Terms of Service
              </a>{' '}
              |{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.optimizely.com/privacy/"
                data-test-section="privacy-link">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div
        data-test-section="signin-sso-2021"
        className="flex signin-wrapper flex-justified--center height--1-1 width--1-1 signin-background--yellow">
        {this.rebrand2021SSO()}
      </div>
    );
  }
}
